import React from 'react';
import HotelCard from './HotelCard';

const BestCards = ({ list = [], title }) => {
	return (
		<div className='pl-[15px] flex flex-col gap-3'>
			<h2 className=' font-medium text-base  text-[#141414] dark:text-white'>{title}</h2>
			<div className='flex  gap-[15px] overflow-x-scroll'>
				<HotelCard list={list} />
			</div>
		</div>
	);
};

export default BestCards;
