import React from 'react';

const Title = ({ size = 'base', weight = 'semibold', color = '#141414', className, text, darkMode, iconLeft, onClick = () => {}, classNameIcon = '' }) => {
	return (
		<p onClick={onClick} className={`my-[14px] inline-flex items-center gap-2 ${darkMode ? '!text-white' : `!text-[${color}]`} font-${weight} text-${size} ${className}`}>
			{iconLeft ? <span className={classNameIcon}>{iconLeft}</span> : null}
			<span>{text}</span>
		</p>
	);
};

export default Title;
