import React from 'react';
import { useTranslation } from 'react-i18next';

const PrestigeBanner = ({ darkMode }) => {
	const { t } = useTranslation();
	return (
		<div className='bg-white dark:bg-[#272829] py-[13px]   flex-col flex  justify-center rounded-[5px] mx-[10px] px-[10px]  gap-3'>
			<div className='flex   gap-[20px] items-center'>
				{darkMode ? <img width={106} src='/images/prestige_white.png' alt='' /> : <img width={106} src='/images/prestige_black.png' alt='' />}
				<div>
					<p className='text-[#000000] w-[220px] dark:text-white  text-sm font-normal'>{t('home.prestige_banner')}</p>
				</div>
			</div>
			<img src='/images/linedashed.svg' className=' object-cover h-[0.5px] ' alt='' />
			<h4 className='text-[#141414] font-medium text-[15px] text-center dark:text-white'>{t('home.we_selected')}</h4>
		</div>
	);
};

export default PrestigeBanner;
