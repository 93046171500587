import React, { useEffect, useState } from 'react';
import useWindowSize from '../utils/customhook/useWindowSize';
import HotelCard from './card/HotelCard';

const SliderTwo = ({ list, darkMode, colorWhite = false, priceShow = false, linktrue }) => {
	const [windowWidth] = useWindowSize();
	const [cardWidth, setCardWidth] = useState(0);
	const [objInfo, setInfoObj] = useState({
		containerWidth: 640,
		cardCount: windowWidth <= 320 ? 1 : 2,
		cardPercentage: windowWidth <= 320 ? 70 : windowWidth <= 640 ? 80 : 89,
	});

	const calculateCardWidth = () => {
		const effectiveContainerWidth = Math.min(objInfo?.containerWidth, windowWidth);
		const totalWidth = effectiveContainerWidth * (objInfo?.cardPercentage / 100);
		return totalWidth / objInfo?.cardCount;
	};

	useEffect(() => {
		const newCardWidth = calculateCardWidth();
		setCardWidth(newCardWidth);
	}, [windowWidth, objInfo?.containerWidth, objInfo?.cardCount, objInfo?.cardPercentage]);

	return (
		<>
			<div
				className='container_main overflow-hidden !pr-0'
				style={{
					width: Math.min(objInfo?.containerWidth, windowWidth),
				}}
			>
				<div className='overflow-x-scroll'>
					<div className='flex space-x-4'>
						<HotelCard list={list} darkMode={darkMode} colorWhite={colorWhite} priceShow={priceShow} cardWidth={200} cardWidthShow={true} linktrue={linktrue} />
					</div>
				</div>
			</div>
		</>
	);
};

export default SliderTwo;
