import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AccardionClickTravel from '../../components/accardion/AccardionClickTravel';
import ButtonMain from '../../components/ButtonMain';
import BestCards from '../../components/card/BestCards';
import NavigationOne from '../../components/NavigationOne';
import SyncScroll from '../../components/SyncScroll';
import GetReviewWidget from '../../components/widget';
import Axios from '../../utils/httpsClinet';

const ClickTravel = ({ darkMode }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { slug } = useParams();
	const dispatch = useDispatch();
	const { home_top } = useSelector(s => s);

	const travelData = {
		1: {
			title: t('home.travelData-title-1'),
			bgImage: '/images/bg-fukok.svg',
			description: t('home.travelData-description-1'),
			tags: [t('home.tag-1'), t('home.tag-2'), t('home.tag-3'), t('home.tag-4'), t('home.tag-5'), t('home.tag-6'), t('home.tag-7')],
			bannerImage: '/images/fukok-banner.svg',
			bestHotelsTitle: t('home.travelData-bestHotelsTitle-1'),
			widgetId: 'vcD1qbpHzwIKPr7L',
			accardion_data: [
				{ question: t('home.1-question'), answer: t('home.1-answer') },
				{ question: t('home.2-question'), answer: t('home.2-answer') },
				{ question: t('home.3-question'), answer: t('home.3-answer') },
				{ question: t('home.4-question'), answer: t('home.4-answer') },
				{ question: t('home.5-question'), answer: t('home.5-answer') },
			],
		},
		2: {
			title: t('home.travelData-title-2'),
			bgImage: '/images/bg-sharm-el-sheikh.svg',
			description: t('home.travelData-description-2'),
			tags: [t('home.tag-8'), t('home.tag-9'), t('home.tag-10'), t('home.tag-11'), t('home.tag-12'), t('home.tag-13')],
			bannerImage: '/images/fukok-banner.svg',
			bestHotelsTitle: t('home.travelData-bestHotelsTitle-2'),
			widgetId: 'aYIIUjJemDqedXYk',
			accardion_data: [
				{ question: t('home.6-question'), answer: t('home.6-answer') },
				{ question: t('home.7-question'), answer: t('home.7-answer') },
				{ question: t('home.8-question'), answer: t('home.8-answer') },
				{ question: t('home.9-question'), answer: t('home.9-answer') },
				{ question: t('home.10-question'), answer: t('home.10-answer') },
			],
		},
	};

	const currentData = travelData[slug] || travelData[1]; // Fallback to id=1 if slug is invalid
	const getTop = () => {
		dispatch({ type: 'SET_LOADING', payload: true });

		Axios()
			.get(`api/v1/search/top-hotels/?type_id=1`)
			.then(res => {
				let data = res?.data?.top_hotels?.data;
				dispatch({ type: 'HOME_TOP', payload: data });
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: 'HOME_TOP', payload: [] });
			})
			.finally(() => {
				dispatch({ type: 'SET_LOADING', payload: false });
			});
	};

	useEffect(() => {
		getTop();
	}, []);

	return (
		<div className={`min-h-screen pb-[65px] ${darkMode ? 'bg-[#141414]' : 'bg-[#EBF0F5]'}`}>
			<NavigationOne darkMode={darkMode} text={'Click Travel'} />
			<div className='container_main !px-0'>
				<img src={currentData?.bgImage} alt='' className='w-full relative top-[45px]' />
				<div className='top-[30px] relative '>
					<div className='bg-white dark:bg-[#272829] py-[20px] rounded-t-[12px] flex flex-col gap-3 mb-[15px]'>
						<div className='px-[10px] flex flex-col gap-4'>
							<h2 className='text-[#141414] dark:text-white font-medium text-xl text-center'>{currentData.title}</h2>
							<p className='text-[#141414AA] dark:text-white text-center text-base  leading-[18px]'>{currentData.description}</p>
							<div className='flex overflow-x-scroll gap-2'>
								<SyncScroll data={currentData.tags} />
							</div>
						</div>
						<div className='pl-[10px]'>
							<GetReviewWidget widgetId={currentData?.widgetId} />
						</div>
						<div className='px-[10px] flex flex-col gap-3'>
							<img src={currentData.bannerImage} alt='' className='w-full' />
							<ButtonMain text='Посмотреть все отели' type_color={'t_blue'} onClick={() => {}} />
						</div>
					</div>
					<BestCards
						list={home_top?.filter(item => (Number(slug) === 1 ? item?.price?.town === 'Фукуок' : Number(slug) === 2 ? item?.price?.town === 'Шарм-Эль-Шейх' : item))}
						title={currentData.bestHotelsTitle}
					/>
					<div className='!mt-4 container_main !px-0'>
						<AccardionClickTravel data={currentData?.accardion_data} darkMode={darkMode} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClickTravel;
