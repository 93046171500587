import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { styled } from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import HeaderDetailParams from '../../components/HeaderDetailParams';
import PrestigeBanner from '../../components/PrestigeBanner';
import Title from '../../components/Title';
import TourIncludes from '../../components/TourIncludes';
import { Back } from '../../components/itemIcon';
import GetReviewWidget from '../../components/widget';
import Axios from '../../utils/httpsClinet';

// Import Swiper styles
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';
import ButtonMain from '../../components/ButtonMain';
import InputDiv2 from '../../components/form/InputDiv2';
import { CloseIcon } from '../../components/homeS3Icon';
import ModalBottom from '../../components/modal/ModalBottom';
import ModalDepartureDate from '../../components/pages/findtour/ModalDepartureDate';
import ModalNumberOfDays from '../../components/pages/findtour/ModalNumberOfDays';
import ModalNumberOfTourists from '../../components/pages/findtour/ModalNumberOfTourists';

const AddFav = styled.div`
	position: relative;
	& .add-favorites {
		border-radius: 50%;
		height: 36px;
		right: 18px;
		top: 16px;
		width: 36px;
		& svg {
			transition: 0.3s;
		}
		& .fixed-svg {
			left: -0.5px;
			position: absolute;
			top: 8.8px;
		}
		& .like {
			transform: scale(0);
		}
		&:active {
			& svg {
				transform: scale(1.3);
			}
		}
	}
	& .liked {
		background: transparent;
	}
`;

const HotelListByHotel = ({ darkMode }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { favourite } = useSelector(s => s);
	const [loading, setLoading] = useState(false);
	const [loading_f, setLoadingF] = useState(false);
	const [favouriteId, setFavouriteId] = useState(0);
	const [items, setItems] = useState([]);
	const [state, setState] = useState({});
	const [favourites, setFavourites] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [strParams, setStrParams] = useState('');
	const [page, setPage] = useState(1);
	const [modal, setModal] = useState(false);
	const [modalNumberOfTourists, setModalNumberOfTourists] = useState(false);
	const [modalDepartureDate, setModalDepartureDate] = useState(false);
	const [modalNumberOfDays, setModalNumberOfDays] = useState(false);
	const [departureDateList, setDepartureDateList] = useState([]);
	const [numberOfDaysList, setNumberOfDaysList] = useState([]);

	// ModalNumberOfTourists
	const [childrenCount, setChildrenCount] = useState(0);
	const [adultCounter, setAdultCounter] = useState(2);
	const [ages, setAges] = useState([]);
	const [addChild, setAddChild] = useState(false);
	const [departureDateObjParams, setDepartureDateObjParams] = useState({
		somo_id: undefined,
		tour_operator_id: undefined,
		state_id: undefined,
		checkin: undefined,
	});
	const [objError, setObjError] = useState({
		departure: false,
		where: false,
		departure_date: false,
		number_of_tourists: false,
		number_of_days: false,
	});
	const [anotherObj, setAnotherObj] = useState({
		town_from_inc: undefined,
		tour_operator_id: undefined,
		state_id: undefined,
		hotel_key: undefined,
	});
	const urlParams = new URLSearchParams(window.location.search);
	const [date, setDate] = useState(new Date());

	const [obj, setObj] = useState({
		popular: '',
		nutrition: '',
		category: '',
	});

	useEffect(() => {
		getFavourites();
		// Parse the search parameters from the URL
		const searchParams = new URLSearchParams(window.location.search);

		// Create an object to store the parameters
		const paramsObject = {};

		// Iterate over the search parameters and add them to the paramsObject
		for (const [key, value] of searchParams.entries()) {
			if (key.endsWith('[]')) {
				const baseKey = key.slice(0, -2); // Remove the '[]' from the key
				if (!paramsObject[baseKey]) {
					paramsObject[baseKey] = [];
				}
				paramsObject[baseKey].push(isNaN(value) ? value : Number(value));
			} else if (key === 'checkin') {
				// Convert checkin date format from YYYY-MM-DD to YYYYMMDD
				paramsObject[key] = value.replace(/-/g, '');
			} else {
				paramsObject[key] = isNaN(value) ? value : Number(value);
			}
		}

		// Set the params state

		console.log(paramsObject, 'a');
		if (paramsObject?.town_from_inc && paramsObject?.tour_operator_id) {
			setAnotherObj(pV => ({
				...pV,
				town_from_inc: paramsObject?.town_from_inc,
				tour_operator_id: paramsObject?.tour_operator_id,
				state_id: paramsObject?.state_id,
				hotel_key: paramsObject?.hotel_key,
				towns: paramsObject?.towns,
			}));

			getDepartureDate(Number(paramsObject?.town_from_inc), Number(paramsObject?.tour_operator_id), Number(paramsObject?.state_id), paramsObject?.checkin, Number(paramsObject?.towns));
			if (paramsObject?.checkin) {
				getNumberOfDays(
					Number(paramsObject?.town_from_inc),
					Number(paramsObject?.tour_operator_id),
					Number(paramsObject?.state_id),
					paramsObject?.checkin,
					Number(paramsObject?.nights)
				);
			}
		}
		if (paramsObject?.adult || paramsObject?.childs) {
			setObj(pV => ({
				...pV,
				number_of_tourists: Number(paramsObject?.adult === undefined ? 2 : paramsObject?.adult) + Number(paramsObject?.childs === undefined ? 0 : paramsObject?.childs),
			}));
			setChildrenCount(Number(paramsObject?.childs === undefined ? 0 : paramsObject?.childs));
			setAdultCounter(Number(paramsObject?.adult === undefined ? 1 : paramsObject?.adult));

			if (paramsObject?.childs != undefined) {
				setAddChild(true);
				setAges(paramsObject?.ages?.length > 0 || paramsObject?.ages != undefined ? paramsObject?.ages : []);
			}
		}

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	useEffect(() => {
		// setLoading(true);
		let searchParams = window.location.search;
		setStrParams(searchParams);

		if (searchParams.includes('&checkin=')) {
			fetchData();
		} else {
			setModal(true);
		}

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const getDepartureDate = (somo_id, tour_operator_id, state_id, checkin, towns) => {
		Axios()
			.get(`api/v1/search/checkins?town_from_inc=${somo_id}&tour_operator_id=${tour_operator_id}&state_id=${state_id}&tours[]=${towns ? towns : anotherObj?.towns}`)
			.then(r => {
				let data = r?.data?.data;
				setDepartureDateObjParams({
					somo_id: somo_id,
					tour_operator_id: tour_operator_id,
					state_id: state_id,
					checkin: checkin,
				});

				const formatData = data.map(item => {
					return {
						...item,
						start: `${item?.start?.slice(0, 4)}-${item?.start?.slice(4, 6)}-${item?.start?.slice(6, 8)}`,
					};
				});

				let findObj = data?.find(el => el?.start === checkin);
				if (checkin != undefined) {
					setObj(pV => ({
						...pV,
						departure_date: `${findObj?.start?.slice(0, 4)}-${findObj?.start?.slice(4, 6)}-${findObj?.start?.slice(6, 8)}`,
					}));
					setDate(new Date(`${findObj?.start?.slice(0, 4)}-${findObj?.start?.slice(4, 6)}-${findObj?.start?.slice(6, 8)}`));
				}
				setDepartureDateList(formatData);
				if (checkin != undefined) {
					getNumberOfDays(somo_id, tour_operator_id, state_id, checkin);
				}
			})
			.catch(e => {
				console.log(e);
			})
			.finally(() => {
				// setLoading(false);
			});
	};

	const getNumberOfDays = (somo_id, tour_operator_id, state_id, checkin, nights) => {
		Axios()
			.get(`api/v1/search/nights?town_from_inc=${somo_id}&tour_operator_id=${tour_operator_id}&state_id=${state_id}&checkins=${checkin}`)
			.then(r => {
				let data = r?.data?.data;
				let findObj = data?.find(el => el?.somo_id === nights);
				if (nights != undefined) {
					setObj(pV => ({
						...pV,
						number_of_days: findObj?.somo_id,
					}));
				}
				setNumberOfDaysList(data);
			})
			.catch(e => {
				console.log(e);
			})
			.finally(() => {
				// setLoading(false);
			});
	};

	const fetchData = searchParams => {
		Axios()
			.get(`api/v1/search/hotel-prices/${searchParams === undefined ? window.location.search : '?' + searchParams}&page=${page}&per_page=3`)

			.then(r => {
				let data = r?.data?.data;

				let result = page == 1 ? data?.data : [...items, ...data?.data];

				setItems(result);
				setState(r?.data?.state);
				setPage(prevPage => prevPage + 1);
				setModal(false);
				if (data?.data.length === 0) {
					setHasMore(false);
				}
			})
			.catch(e => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const changeFavourite = id => {
		setLoadingF(true);
		setFavouriteId(id);
		Axios()
			.post(`api/v1/like-prices`, { price_id: id })
			.then(res => {
				const isFavorited = favourites?.some(fav => fav.price_id === id);
				if (isFavorited) {
					setFavourites(favourites.filter(favid => favid?.price_id !== id));
				} else {
					setFavourites([...favourites, { price_id: id }]);
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoadingF(false);
			});
	};
	const getFavourites = () => {
		Axios()
			.get(`api/v1/get-like-prices`)
			.then(res => {
				let data = res?.data?.data?.data;
				let newD = [];
				data.forEach(el => {
					newD = [
						...newD,
						{
							price_id: el?.price?.id,
						},
					];
				});
				setFavourites(newD);
			});
	};
	const colors = ['#FF5733', '#33FF57', '#3B71FE', '#0096FF', '#FFC273'];

	const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];
	const handleFind = () => {
		let t = true,
			error = {};

		if (!obj?.departure_date) {
			t = false;
			error = { ...error, departure_date: true };
		}
		if (!obj?.number_of_tourists) {
			t = false;
			error = { ...error, number_of_tourists: true };
		}
		if (!obj?.number_of_days) {
			t = false;
			error = { ...error, number_of_days: true };
		}
		if (t) {
			const filter = {
				town_from_inc: anotherObj?.town_from_inc,
				tour_operator_id: anotherObj?.tour_operator_id,
				state_id: anotherObj?.state_id,
				checkin: obj?.departure_date,
				nights: obj?.number_of_days,
				// town: checkedItems.map((item) => item.somo_id),
				adult: obj?.number_of_tourists,
				childs: childrenCount,
				hotel_key: anotherObj?.hotel_key,
				towns: anotherObj?.towns ? [anotherObj?.towns] : [],
			};

			const formatDate = dateStr => {
				const [year, month, day] = dateStr.split('-');
				return `${year}${month}${day}`;
			};

			const searchParamss = Object.keys(filter)
				.map(key => {
					if (Array.isArray(filter[key])) {
						return filter[key].map(value => `${key}[]=${encodeURIComponent(value)}`).join('&');
					}
					if (key === 'checkin') {
						return `${key}=${encodeURIComponent(formatDate(filter[key]))}`;
					}
					return `${key}=${encodeURIComponent(filter[key])}`;
				})
				.join('&');

			setStrParams('?' + searchParamss);
			fetchData(`${searchParamss}`);
			navigate(`/hotels/result-hotel/?${searchParamss}`);
		} else {
			setObjError(error);
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};
	return (
		<div className={`min-h-screen pb-[75px] bg-neutralSand dark:bg-[#141414] `}>
			{loading ? (
				<div className='container_main !px-0'>
					<div className='bg-white dark:bg-[#272829] rounded-bl-[20px] rounded-br-[20px] py-[20px]'>
						<div className='flex items-center w-full pl-[15px]'>
							<Back
								color={darkMode ? '#fff' : '#141414'}
								// onClick={() => navigate(`/findtour/${window.location.search}`)}
								// className='-translate-x-[15px]'
								onClick={() => navigate(`/${window.location.search}`)}
								className='-translate-x-[15px]'
							/>

							<HeaderDetailParams darkMode={darkMode} />
						</div>
						{/* <div className='overflow-x-scroll'>
								<div className='inline-flex items-center whitespace-nowrap gap-2 mx-[15px] mt-[25px]'>
									<SelectDiv placeholder='Популярные' title='Популярные' value={obj?.popular} onClick={() => setModalPopular(true)} darkMode={darkMode} />
									<SelectDiv placeholder='Питание' title='Питание' value={obj?.nutrition} onClick={() => setModalNutrition(true)} darkMode={darkMode} />
									<SelectDiv placeholder='Категории' title='Категории' value={obj?.category} onClick={() => setModalCategory(true)} darkMode={darkMode} />
								</div>
							</div> */}
					</div>

					<div className='overflow-x-scroll flex gap-2 whitespace-nowrap mt-3'>
						{[...Array(5)].map((_, index) => (
							<Skeleton key={index} width={153} height={48} style={{ borderRadius: '15px' }} baseColor='#cccccc' />
						))}
					</div>
					<div className='mt-[25px]'>
						<Skeleton height={48} style={{ borderRadius: '15px' }} baseColor='#cccccc' />
					</div>
					<div className='mt-[15px]'>
						{[...Array(1)].map((_, index) => (
							<Skeleton key={index} height={200} style={{ borderRadius: '15px' }} baseColor='#cccccc' />
						))}
					</div>
					<TourIncludes />
					<div className='mt-[15px]'>
						{[...Array(1)].map((_, index) => (
							<Skeleton key={index} height={200} style={{ borderRadius: '15px' }} baseColor='#cccccc' />
						))}
					</div>
					{/* <div className='fixed z-[1300] bottom-0 left-0 w-full flex bg-white px-[15px] py-1'>
						<div className='w-full'>
							<Skeleton height={48} style={{ borderRadius: '15px' }} baseColor='#cccccc' />
						</div>
					</div> */}
				</div>
			) : (
				// <LoadingMain />
				<>
					<div className=' fixed    z-999 w-full bg-white dark:bg-[#272829] rounded-bl-[20px] rounded-br-[20px]  h-[75px]  mb-[14px] p-[15px]'>
						<div className='container_main flex items-center w-full  gap-[10px] '>
							<Back
								color={darkMode ? '#fff' : '#141414'}
								// onClick={() => navigate(`/findtour/${window.location.search}`)}
								onClick={() => navigate(`/${window.location.search}`)}
								className=''
							/>

							<HeaderDetailParams darkMode={darkMode} />
							<div></div>
						</div>
					</div>
					<div className='container_main !px-0'>
						<div className='pt-[89px]  mb-3'>
							<GetReviewWidget widgetId={state?.widget} />
						</div>
						<PrestigeBanner darkMode={darkMode} />
						<div className=''>
							<div
								dataLength={items.length}
								hasMore={hasMore}
								loader={<span className='flex justify-center mt-[15px] h-[50px] '></span>}
								endMessage={<p className='mb-0'></p>}
							>
								{items.map((item, index) => {
									let clickTag = [];
									item?.tags?.forEach(el => {
										clickTag = [
											...clickTag,
											{
												title: el?.tag,
												color: el?.color,
											},
										];
									});
									const isAddedToFavourite = favourites?.some(d => d.price_id === item.price?.id);

									let settings = {
										infinite: item?.photos?.length === 1 ? false : true,
										dots: false,
										speed: 700,
										slidesToShow: 1,
										slidesToScroll: 1,
										spaceBetween: 30,
										autoplay: item?.photos?.length === 1 ? false : true,
										autoplaySpeed: 5000,
										pauseOnFocus: false,
										appendDots: dots => (
											<div>
												<ul style={{ margin: '0px' }}> {dots} </ul>
											</div>
										),
										customPaging: i => <div className='custompage'></div>,
									};
									return (
										<React.Fragment key={index}>
											<div className='min-h-[271px] mt-[15px] overflow-hidden rounded-[18.07px] bg-white dark:bg-[#272829]'>
												<div className='relative h-[193px] overflow-hidden'>
													{/* <img
														onError={e => {
															e.target.src = 'https://cdn0.hitched.co.uk/vendor/4332/3_2/1280/png/frame-1_4_24332-169089063311545.webp';
														}}
														src={item?.image_url}
														className='w-full h-full object-cover rounded-tl-[18.07px] rounded-tr-[18.07px]'
														alt=''
													/> */}
													{/* <Link to={'/hotels/detail/gallery/' + window.location.search}> */}
													{item?.photos?.length > 0 ? (
														<Swiper
															pagination={{
																dynamicBullets: true,
															}}
															modules={[Pagination]}
															className='mySwiper'
														>
															{item?.photos?.map((item_in, index_in) => (
																<SwiperSlide>
																	<img
																		key={index_in}
																		src={item_in?.full_path}
																		className='w-full h-full object-cover rounded-tl-[18.07px] rounded-tr-[18.07px]'
																		alt=''
																	/>
																</SwiperSlide>
															))}
														</Swiper>
													) : (
														<img
															onError={e => {
																e.target.src =
																	'https://cdn0.hitched.co.uk/vendor/4332/3_2/1280/png/frame-1_4_24332-169089063311545.webp';
															}}
															src={item?.image_url}
															className='w-full h-full object-cover rounded-tl-[18.07px] rounded-tr-[18.07px]'
															alt=''
														/>
													)}
													<div className='absolute z-20 top-[16.5px] left-[16.5px] inline-flex gap-1 items-center bg-white rounded-[10px] px-[8.5px] py-[5.5px]'>
														<img src='/images/star.svg' alt='' />
														<p className='font-medium text-sm'>{item?.star}</p>
													</div>
													<div className='absolute z-20 bottom-[16.5px] right-[16.5px] inline-flex gap-10  items-center text-white bg-[#3B71FE] rounded-[10px] px-[10px] h-[30px] py-[10px]'>
														<p className='font-normal text-[15px]'>Кэшбек 2%</p>
													</div>
													{item?.rating?.[0]?.rating ? (
														<div className='absolute z-20 bottom-[16.5px] left-[16.5px] inline-flex gap-10  items-center text-white bg-white dark:bg-[#272829] rounded-[10px] px-[10px] h-[30px] py-[10px]'>
															<div className='flex gap-[5px] '>
																<div className='pl-2  text-[#141414] dark:text-white text-[19px] font-normal'>
																	{item?.rating?.[0]?.rating}
																</div>
																{darkMode ? (
																	<img className='pl-2' src='/images/tripadvisor-white.svg' alt='' />
																) : (
																	<img src='/images/tripadvisor.svg' alt='' />
																)}{' '}
															</div>
														</div>
													) : null}
													<div className='absolute top-[16.5px] right-[16.5px] z-30'>
														<AddFav className='fav bg-[#FFFFFFBF] rounded-[10px] p-[10px] w-[35px] h-[35px] flex justify-center items-center'>
															<button
																onClick={() => changeFavourite(item?.price?.id)}
																className={!isAddedToFavourite ? 'fixed-svg' : 'like fixed-svg'}
															>
																<>
																	{loading_f && favouriteId === item?.id ? (
																		<ClipLoader size={24} />
																	) : (
																		<>
																			{isAddedToFavourite ? (
																				<svg
																					xmlns='http://www.w3.org/2000/svg'
																					width='28'
																					height='26'
																					viewBox='0 0 28 26'
																					fill='none'
																				>
																					<path
																						d='M24.069 3.05825L24.0683 3.05765C21.214 0.501861 17.0517 1.03082 14.4771 3.78138L14.4771 3.78133L14.4707 3.78836L14.0122 4.29406L13.5228 3.7813C10.9911 1.02672 6.78223 0.505279 3.93168 3.05765L3.93101 3.05825C0.727822 5.93508 0.579672 11.0279 3.42259 14.1131L3.42353 14.1141L12.5035 23.9224C13.3024 24.8222 14.6976 24.8222 15.4965 23.9224L24.5765 14.1141L24.5774 14.1131C27.4203 11.0279 27.2722 5.93508 24.069 3.05825Z'
																						fill='#235DFF'
																						stroke='#235DFF'
																						stroke-width='1.19444'
																					/>
																				</svg>
																			) : (
																				<svg
																					xmlns='http://www.w3.org/2000/svg'
																					width='28'
																					height='26'
																					viewBox='0 0 28 26'
																					fill='none'
																				>
																					<path
																						d='M24.069 3.05825L24.0683 3.05765C21.214 0.501861 17.0517 1.03082 14.4771 3.78138L14.4771 3.78133L14.4707 3.78836L14.0122 4.29406L13.5228 3.7813C10.9911 1.02672 6.78223 0.505279 3.93168 3.05765L3.93101 3.05825C0.727822 5.93508 0.579672 11.0279 3.42259 14.1131L3.42353 14.1141L12.5035 23.9224C13.3024 24.8222 14.6976 24.8222 15.4965 23.9224L24.5765 14.1141L24.5774 14.1131C27.4203 11.0279 27.2722 5.93508 24.069 3.05825Z'
																						fill='white'
																						stroke='#235DFF'
																						stroke-width='1.19444'
																					/>
																				</svg>
																			)}
																		</>
																	)}
																</>
															</button>
														</AddFav>
													</div>
												</div>
												<Title
													darkMode={darkMode}
													size='[20px]'
													className='px-2 cursor-pointer hover:underline'
													text={item?.name}
													onClick={() => {
														navigate(`/hotels/detail/${strParams}&id=${item?.price?.id}`);
													}}
												/>

												{/* {clickTag?.length > 0 ? (
													<ClickTravel
														list={clickTag}
														title={t('home.hotel_tag')}
														darkMode={darkMode}
														shadow={false}
														titleShow={false}
														randomTrue={true}
														getRandomColor={getRandomColor}
													/>
												) : null} */}

												{item?.short_description ? <p className='text-[#141414] dark:text-white  px-2'>{item?.short_description}</p> : null}
												{clickTag?.length > 0 ? (
													<div className='flex gap-[5px]  overflow-x-scroll  py-[10px] px-2'>
														{clickTag?.map((item, index_tag) => {
															return (
																<div
																	key={index_tag}
																	className='bg-[#EBF0F5] whitespace-nowrap dark:bg-[#141414] dark:text-white px-[10px] py-[5px] rounded-[20px] text-[#76787A] font-normal text-sm !font-urbanist '
																>
																	{item?.title}
																</div>
															);
														})}
													</div>
												) : null}
												<div className=''>
													<img src='/images/linedashed.svg' className='w-full object-cover h-[0.5px] mt-[15px]' alt='' />
													{/* <Link to="/hotels/detail/hoteltourbooking/"> */}
													<Link to={`/hotels/detail/${strParams}&id=${item?.price?.id}&hotel_key=${item?.hotel_key}`}>
														<div className='p-[15px] flex gap-3 justify-between w-full relative'>
															<div className={`text-base font-normal ${darkMode ? '!text-white' : ''}`}>
																<span>
																	{item?.price?.adult} {t('home.turist')},{' '}
																</span>
																<span>
																	{item?.price?.nights} {t('home.nights')}
																</span>
															</div>
															<div className='flex items-center gap-3'>
																<p className={`font-semibold text-xl ${darkMode ? '!text-white' : ''}`}>
																	{item?.price?.converted_price_number?.toLocaleString('en-US').replace(/,/g, ' ')} {t('home.uzs')}
																</p>
															</div>
														</div>
													</Link>
												</div>
											</div>

											{index === 0 ? <TourIncludes /> : null}
										</React.Fragment>
									);
								})}
							</div>
							<ModalBottom
								close={() => {
									if (window.location.search.includes('&checkin=')) {
										setModal(false);
									} else {
										setModal(true);
									}
								}}
								modal={modal}
								heightModal='min-h-[40vh] max-h-[80vh]'
								translateY='translate-y-[80vh]'
								btnShow={false}
								content={
									<>
										<div className='w-full'>
											<div className='flex items-center justify-between w-full gap-3'>
												<p></p>
												<CloseIcon
													fill={darkMode ? '#fff' : '#141414'}
													className='cursor-pointer'
													onClick={() => {
														if (anotherObj?.checkin) {
															setModal(false);
														} else {
															navigate(-1);
														}
													}}
												/>
											</div>{' '}
											<div className='mt-[20px]'>
												<div className='grid grid-cols-2 gap-6 w-full'>
													<div className='col-span-2'>
														<InputDiv2
															placeholder={t('home.select')}
															title={t('home.departure_date')}
															error={objError?.departure_date ? true : false}
															value={obj?.departure_date}
															onClick={() => setModalDepartureDate(true)}
															darkMode={darkMode}
															icon={<img src='/images/calendar.svg' />}
														/>
													</div>
													<div className='col-span-1'>
														<InputDiv2
															placeholder={t('home.select')}
															title={t('home.number_of_days')}
															error={objError?.number_of_days ? true : false}
															value={obj?.number_of_days}
															onClick={() => setModalNumberOfDays(true)}
															darkMode={darkMode}
															icon={<img src='/images/calendarday.svg' className='w-[20px] h-[20px] mt-[2px]' />}
														/>
													</div>
													<div className='col-span-1'>
														<InputDiv2
															placeholder={t('home.select')}
															title={t('home.number_of_tourists')}
															error={objError?.number_of_tourists ? true : false}
															value={obj?.number_of_tourists}
															onClick={() => setModalNumberOfTourists(true)}
															darkMode={darkMode}
															icon={<img src='/images/grouppeople.svg' />}
														/>
													</div>

													<div className='col-span-2'>
														<ButtonMain
															onClick={handleFind}
															type='button'
															className='w-full'
															text={t('home.select_numbers')}
															type_color='t_blue'
														/>
													</div>
												</div>
											</div>
										</div>
									</>
								}
							/>
							<ModalDepartureDate
								modalDepartureDate={modalDepartureDate}
								setModalDepartureDate={setModalDepartureDate}
								setObj={setObj}
								obj={obj}
								darkMode={darkMode}
								objError={objError}
								setObjError={setObjError}
								departureDateList={departureDateList}
								date={date}
								setDate={setDate}
								departureDateObjParams={departureDateObjParams}
								setDepartureDateObjParams={setDepartureDateObjParams}
								getDepartureDate={getDepartureDate}
								setModalNumberOfTourists={setModalNumberOfTourists}
								setModalNumberOfDays={setModalNumberOfDays}
							/>
							<ModalNumberOfTourists
								modalNumberOfTourists={modalNumberOfTourists}
								setModalNumberOfTourists={setModalNumberOfTourists}
								setObj={setObj}
								obj={obj}
								objError={objError}
								setObjError={setObjError}
								childrenCount={childrenCount}
								setChildrenCount={setChildrenCount}
								adultCounter={adultCounter}
								setAdultCounter={setAdultCounter}
								ages={ages}
								setAges={setAges}
								addChild={addChild}
								setAddChild={setAddChild}
								setModalNumberOfDays={setModalNumberOfDays}
							/>
							<ModalNumberOfDays
								modalNumberOfDays={modalNumberOfDays}
								setModalNumberOfDays={setModalNumberOfDays}
								list={numberOfDaysList}
								setObj={setObj}
								obj={obj}
								darkMode={darkMode}
								objError={objError}
								setObjError={setObjError}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default HotelListByHotel;
