import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBottom from '../../modal/ModalBottom';

const ModalNumberOfTourists = ({
	modalNumberOfTourists,
	setModalNumberOfTourists,
	list,
	obj,
	setObj,
	objError,
	setObjError,
	childrenCount,
	setChildrenCount,
	adultCounter,
	setAdultCounter,
	ages,
	setAges,
	addChild,
	setAddChild,
	setModalNumberOfDays,
	darkMode,
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		setObj(pV => ({
			...pV,
			number_of_tourists: obj?.number_of_tourists,
		}));
	}, [obj?.number_of_tourists]);

	const handleAdultCounter = delta => {
		const newCount = adultCounter + delta;
		if (newCount >= 1) {
			setAdultCounter(newCount);
		}
	};
	const handleChildrenChange = delta => {
		const newCount = Math.max(0, childrenCount + delta <= 4 ? childrenCount + delta : 4);
		setChildrenCount(newCount);

		if (newCount > childrenCount) {
			const newAges = [...ages];
			for (let i = childrenCount + 1; i <= newCount; i++) {
				newAges.push(1);
			}
			setAges(newAges);
		} else {
			setAges(ages.slice(0, newCount));
		}
	};

	const handleAgeChange = (index, delta) => {
		const newAges = ages.map((age, i) => (i === index ? delta : age));
		setAges(newAges);
	};

	const ageCategory = age => {
		if (age === 1 || age === 0) {
			return age + ` ${t('home.year')}`;
		} else if (age >= 2 && age <= 4) {
			return age + ` ${t('home.year1')}`;
		} else if (age >= 5 && age <= 17) {
			return age + ` ${t('home.year3')}`;
		} else {
			return age + ` ${t('home.year4')}`;
		}
	};

	const clickFunction = () => {
		let allCount = adultCounter + ages?.length;
		setObj(pV => ({
			...pV,
			number_of_tourists: allCount,
		}));
		setObjError(pV => ({
			...pV,
			number_of_tourists: false,
		}));
		setModalNumberOfTourists(false);
		// setModalNumberOfDays(true);
	};

	const closeFunction = () => {
		setModalNumberOfTourists(false);
		// setAddChild(false);
		// setChildrenCount(0);
		// setAdultCounter(1);
		// setAges([]);
		// setObj((pV) => ({
		//   ...pV,
		//   number_of_tourists: 1,
		// }));
	};

	return (
		<div className='w-full'>
			<ModalBottom
				close={() => closeFunction()}
				modal={modalNumberOfTourists}
				heightModal='min-h-[20vh] max-h-[80vh]'
				btnShow={true}
				textBtn={t('home.save')}
				translateY='translate-y-[80vh]'
				clickFunction={clickFunction}
				content={
					<div className='w-full '>
						<div className='flex items-center justify-between w-full gap-3'>
							<p className='font-medium text-xl dark:text-white'>{t('home.number_of_tourists2')}</p>
						</div>

						<div className='mt-3 flex flex-col gap-3'>
							<div className='flex items-center justify-between  dark:bg-[#272829] bg-white rounded-xl px-[15px] py-[15px]'>
								<span className='mr-2  flex flex-col'>
									<span className='text-[17px] font-normal select-none dark:text-white'>{t('home.adults')}</span>
									<span className=' text-sm text-[#76787A]'>{t('home.over_16_years_old')}</span>
								</span>
								<div className='flex items-center gap-1 bg-[#EBF0F5] dark:bg-[#141414] rounded-full p-1'>
									<button
										onClick={() => handleAdultCounter(-1)}
										className='select-none  w-[27px] h-[27px] bg-[#0077FF] flex justify-center items-center text-white	 font-normal text-base rounded-full'
									>
										-
									</button>
									<span className='select-none mx-2 font-normal text-lg dark:text-white'>{adultCounter}</span>
									<button
										onClick={() => handleAdultCounter(1)}
										className='select-none w-[27px] h-[27px] bg-[#0077FF] flex justify-center items-center text-white font-normal text-base rounded-full'
									>
										+
									</button>
								</div>
							</div>
							<div className='flex items-center justify-between  bg-white dark:bg-[#272829] rounded-xl px-[15px]'>
								{!addChild ? (
									<div className='text-base font-normal text-blueRibbon w-full py-[15px] cursor-pointer dark:text-white' onClick={() => setAddChild(true)}>
										+ {t('home.add_childs')}
									</div>
								) : (
									<div className='flex items-center justify-between w-full py-[15px] '>
										<div className='flex flex-col '>
											<span className='mr-2 font-normal text-[17px] dark:text-white'>{t('home.childs')}</span>
											<span className=' text-sm text-[#76787A]'>{t('home.2_to_12_years_old')}</span>
										</div>
										<div className='flex items-center gap-1 bg-[#EBF0F5] dark:bg-[#141414]  rounded-full p-1'>
											<button
												onClick={() => handleChildrenChange(-1)}
												className='select-none w-[27px] h-[27px] bg-[#0077FF] flex justify-center items-center text-white font-normal text-base rounded-full'
											>
												-
											</button>
											<span className='select-none mx-2 font-normal text-lg dark:text-white'>{childrenCount}</span>
											<button
												onClick={() => handleChildrenChange(1)}
												className='select-none w-[27px] h-[27px] bg-[#0077FF] flex justify-center items-center text-white font-normal text-base rounded-full'
											>
												+
											</button>
										</div>
									</div>
								)}
							</div>

							{ages.map((age, index) => (
								<div key={index} className='flex items-center justify-between w-full py-[15px] bg-white dark:bg-[#272829] rounded-xl px-[15px]'>
									<div className='flex flex-col'>
										<span className='mr-2  font-normal text-[17px] dark:text-white'>{t('home.child_age')}</span>
										<span className='text-sm text-[#76787A]'>
											{index + 1}-{t('home.th_child')}
										</span>
									</div>

									{/* <div className='flex items-center gap-1'>
										<button
											onClick={() => handleAgeChange(index, -1)}
											className='select-none w-[27px] h-[27px] bg-[#D7DBE0] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
										>
											-
										</button>
										<span className='select-none mx-2 font-semibold text-lg dark:text-white'>{ageCategory(age)}</span>
										<button
											onClick={() => handleAgeChange(index, 1)}
											className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
										>
											+
										</button>
									</div> */}
									<select
										value={age}
										onChange={e => handleAgeChange(index, Number(e.target.value))}
										className='select-none bg-[#EEF2FF] dark:bg-[#2c2c2e] border border-gray-300 dark:border-[#4b4b4d] rounded-lg px-3 py-2 text-base dark:text-white'
									>
										{/* 1 dan 17 gacha yoshlarni tanlash uchun variantlar */}
										{Array.from({ length: 17 }, (_, i) => i + 1).map(num => (
											<option key={num} value={num}>
												{num}
											</option>
										))}
									</select>
								</div>
							))}
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ModalNumberOfTourists;
