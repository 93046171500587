import React from 'react';
import AccardionItemClickTravel from './AccardionItemClickTravel';

const AccardionClickTravel = ({ data, darkMode }) => {
	return (
		<div className='px-[15px] flex flex-col gap-3'>
			{data.map((item, index) => (
				<AccardionItemClickTravel darkMode={darkMode} key={index} question={item.question} answer={item.answer} />
			))}
		</div>
	);
};

export default AccardionClickTravel;
